// import { useEffect, useState } from 'react';
import '@livekit/components-styles';
import '@livekit/components-styles/prefabs';
import EgressHelper from '@livekit/egress-sdk';
import './App.css';
import RoomPage from './Room';
// var roomData;

function App() {
  return (
    <div className="App">
      <div className="video-container">
        <RoomPage
          // EgressHelper retrieves parameters passed to the page
          url={EgressHelper ? EgressHelper?.getLiveKitURL() : ""}
          token={EgressHelper ? EgressHelper?.getAccessToken() : ""}
          layout={EgressHelper ? EgressHelper?.getLayout() : ""}
        />
      </div>
    </div>
  );
}

export default App;
